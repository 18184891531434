import React from 'react'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Loader from '../Loader'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.body,
    width: '100%',
    height: '100%',
    zIndex: 99,
  },
}))

interface LoaderBackdropProps {
  className?: {
    backdrop?: string
    loader?: string
  }
}

const LoaderBackdrop: React.FC<LoaderBackdropProps> = ({ className = {} }) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, className.backdrop)}>
      <Loader size={80} classNames={cn(classes.root, className.loader)} />
    </div>
  )
}

export default LoaderBackdrop
