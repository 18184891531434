import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fade,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { reduxForm } from 'redux-form'

import { actionRequestOtpCode } from '../../../store/auth/actions'
import { openNotification } from '../../../store/notification/actions'
import { closeOtpDialog, setOtpDialogState } from '../../../store/otp/actions'
import { selectOtpState } from '../../../store/otp/selectors'
import sankakuLogo from '../../Logo/triangle-orange.svg'
import SlideUp from '../../Transitions/SlideUp'

import { AuthFactor, MultiFactorAuthMethod } from '../../../../enums'
import { selectFormState } from '../../../store/app/selectors'
import { OtpStateType } from '../../../store/otp/reducer'
import shieldCheck from './shield-check.svg'
import useEntryQuery from '../../../hooks/useEntryQuery'
import { POST_MESSAGE_TYPE } from '../../../utils/helpers/network-service'
import { selectPasskeyState } from '../../../store/passkeys/selectors'
import LoaderBackdrop from '../../Loading/Loader/LoaderBackdrop'

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 24px 24px 24px',
    overflowY: 'initial',
    '& .react-code-input': {
      margin: '8px',
      '& input': {
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
  contentBox: {
    width: '100%',
    maxHeight: '100%',
  },
  flex: {
    display: 'flex',
  },
  controlDialog: {
    padding: '12px',
  },
  checkboxRemember: {
    color: (theme.palette as any).textDialog,
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '8px 16px',
    '& button': {
      marginLeft: '8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
  },
  shortText: {
    margin: 'auto',
    width: '225px',
  },
  backButton: {
    marginRight: 'auto',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  boldText: {
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  normalText: {
    margin: '16px 0',
  },
  submitButton: {
    margin: '16px',
  },
  pointer: {
    cursor: 'pointer',
  },
  textAlignStart: {
    textAlign: 'start',
  },
  loadingBtn: {
    width: '20px !important',
    height: '20px !important',
    marginLeft: 10,
  },
  backdrop: {
    backgroundColor: `${theme.palette.backdropColor} !important`,
  },
  loader: {
    backgroundColor: 'transparent !important',
  },
}))

const initOtp = { code: '', rememberDevice: false, sentCode: false, verified: false, challenge: '' }

const getCodeInputStyles = ({ fullScreen, theme }: { fullScreen: boolean; theme: Theme }) => ({
  inputStyle: {
    margin: `16px ${fullScreen ? '5px' : '10px'}`,
    width: '36px',
    fontSize: '40px',
    height: '44px',
    backgroundColor: 'transparent',
    color: theme.palette.txtCodeInputColor,
    borderWidth: '0px 0px 2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.txtCodeInputColor,
    padding: 0,
    textAlign: 'center',
  },
  inputStyleInvalid: {
    margin: `16px ${fullScreen ? '5px' : '10px'}`,
    width: '36px',
    fontSize: '40px',
    height: '44px',
    backgroundColor: 'transparent',
    color: 'red',
    borderWidth: '0px 0px 2px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'red',
    textAlign: 'center',
  },
})

const VerifyCode = () => {
  const { t } = useTranslation()
  const dispatch: AsyncDispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { query } = useEntryQuery()

  const otpDialogState: OtpStateType = useSelector(selectOtpState)
  const [error, setError] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [otp, setOtp] = useState(initOtp)
  const [factor, setFactor] = useState('')
  const [showVerifiedDialog, setShowVerifiedDialog] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const loginFormState = useSelector(selectFormState('login'))
  const passkeyState = useSelector(selectPasskeyState)
  const isIframeMode = query?.is_iframe === 'true'

  const inputStyles = getCodeInputStyles({ fullScreen, theme })

  const handleClose = (
    data: { actionType?: string; isVerified?: boolean; rememberedAddress?: boolean } = {
      actionType: '',
    },
  ) => {
    setOtp(initOtp)
    setError(false)
    dispatch(closeOtpDialog(data))
  }

  const handleChangeOtp = (value: {
    code?: string
    rememberDevice?: boolean
    verified?: boolean
  }) => {
    setError(false)
    setOtp({
      ...otp,
      ...value,
    })
    if (value.code) {
      setCanSubmit(value.code.length === 6)
    }
  }

  const handleSubmit = () => {
    setCanSubmit(false)
    dispatch(
      setOtpDialogState({
        data: {
          code: otp.code,
          challenge: otpDialogState?.data.challenge,
        },
      }),
    )
  }

  const requestOtpCode = async (factor = AuthFactor.TOTP) => {
    if (!otpDialogState.actionType) handleClose()
    try {
      await dispatch(
        actionRequestOtpCode({
          login: loginFormState?.values?.email || passkeyState?.data?.email,
          authenticationFactor: factor,
        }),
      )
      if (factor === AuthFactor.TOTP) {
        if (!otp.sentCode) {
          setOtp({ ...otp, sentCode: true })
        }
        dispatch(
          openNotification({
            message: t('dialog__verify-otp_send_successfully'),
            messageType: 'code',
          }),
        )
      }
    } catch (errorRequestOtp) {
      console.log(errorRequestOtp)
    }
  }

  useEffect(() => {
    switch (otpDialogState.mfaMethod) {
      case MultiFactorAuthMethod.MAIL:
        setFactor(AuthFactor.TOTP)
        break
      case MultiFactorAuthMethod.AUTHENTICATOR:
        setFactor(AuthFactor.AUTHENTICATOR)
        break
      default:
        break
    }
  }, [otpDialogState.mfaMethod])

  useEffect(() => {
    if (otp.verified || otpDialogState.isVerified) {
      setShowVerifiedDialog(true)
      setTimeout(() => {
        setShowVerifiedDialog(false)
        handleClose({ actionType: '', isVerified: true })
      }, 2000)
    }
  }, [otp.verified, otpDialogState.isVerified])

  useEffect(() => {
    if (otpDialogState.isError) {
      setError(true)
      setCanSubmit(true)
    }
    if (!otpDialogState.visible) {
      setOtp(initOtp)
    }
    if (isIframeMode) {
      window.parent.postMessage(
        { type: POST_MESSAGE_TYPE.IFRAME_MFA, visible: otpDialogState.visible },
        '*',
      )
    }
  }, [otpDialogState.isError, otpDialogState.visible])

  useEffect(() => {
    if (otp.code.length === 6) {
      handleSubmit()
    }
  }, [otp.code])

  const renderQRForm = (
    <>
      <Typography align="left" className={cn(classes.boldText, classes.normalText)}>
        {t('dialog__verify-otp_title')}
      </Typography>
      <DialogContentText align="left">{t('dialog__verify-otp_guide_get-code')}</DialogContentText>
      <ReactCodeInput
        name="otp_code"
        type="number"
        fields={6}
        value={otp.code}
        onChange={(code) => handleChangeOtp({ code })}
        isValid={!error}
        inputStyle={inputStyles.inputStyle as React.CSSProperties}
        inputStyleInvalid={inputStyles.inputStyleInvalid as React.CSSProperties}
        inputMode="numeric"
      />
      <Button
        className={classes.submitButton}
        disabled={!!error || !canSubmit}
        variant="contained"
        onClick={handleSubmit}
        color="primary"
      >
        {t('dialog__verify-otp_submit')}
      </Button>
    </>
  )

  const renderGetOtpForm = (
    <>
      <Typography className={cn(classes.boldText, classes.normalText)}>
        {t('dialog__verify-otp_send-title')}
      </Typography>
      <Typography className={cn(classes.normalText, classes.textAlignStart)}>
        <Trans i18nKey="dialog__verify-otp_send-instruction">
          Clicking the button below will trigger the delivery of <b>a One-Time Password (OTP)</b>
          to your registered email address for account:
          <b>{{ account: loginFormState?.values?.email || passkeyState?.data?.email }}</b>.
          <p>Please ensure to check your inbox (including spam/junk folder) for the OTP.</p>
        </Trans>
      </Typography>
      <Button
        className={classes.submitButton}
        variant="contained"
        onClick={() => requestOtpCode()}
        color="primary"
      >
        {t('dialog__verify-otp_get')}
      </Button>
    </>
  )

  const renderEnterEmailOtpForm = (
    <>
      <Typography className={cn(classes.boldText, classes.normalText)}>
        {t('dialog__verify-otp_title')}
      </Typography>
      <Typography>
        <Trans i18nKey="dialog__verify-otp_enter-code">
          Enter the one-time password sent to the email account registered with the following
          account:
          <b>{{ account: loginFormState?.values?.email || passkeyState?.data?.email }}</b>.
        </Trans>
      </Typography>
      <ReactCodeInput
        name="otp_code"
        type="number"
        fields={6}
        value={otp.code}
        onChange={(code) => handleChangeOtp({ code })}
        isValid={!error}
        inputStyle={inputStyles.inputStyle as React.CSSProperties}
        inputStyleInvalid={inputStyles.inputStyleInvalid as React.CSSProperties}
        inputMode="numeric"
      />
      <Typography>
        {t('dialog__verify-otp_not-receive')}{' '}
        <Link className={classes.pointer} onClick={() => requestOtpCode()}>
          {t('dialog__verify-otp_resend')}
        </Link>
      </Typography>
      <Button
        className={classes.submitButton}
        disabled={!!error || !canSubmit}
        variant="contained"
        onClick={handleSubmit}
        color="primary"
      >
        {t('dialog__verify-otp_submit')}
      </Button>
    </>
  )

  if (otpDialogState.data.code && (otp.verified || otpDialogState.isVerified)) {
    return (
      <Dialog
        open={showVerifiedDialog}
        fullScreen={fullScreen}
        fullWidth
        TransitionComponent={(fullScreen && SlideUp) || Fade}
      >
        <DialogContent className={classes.dialogContent}>
          <div className={classes.contentBox}>
            <div className={classes.logoContainer}>
              <img src={shieldCheck} loading="lazy" />
            </div>
            <Typography className={cn(classes.boldText, classes.shortText)}>
              {t('dialog__verify-otp_verified')}
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={otpDialogState.visible}
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={(fullScreen && SlideUp) || Fade}
      hideBackdrop={isIframeMode}
      PaperProps={{
        style: {
          boxShadow: isIframeMode ? 'none' : undefined,
        },
      }}
    >
      {otpDialogState.loading && (
        <LoaderBackdrop className={{ backdrop: classes.backdrop, loader: classes.loader }} />
      )}
      {isIframeMode ? (
        <div className={cn(classes.controlDialog, classes.flex)}>
          <IconButton
            className={cn(classes.backButton, classes.flex)}
            onClick={() => handleClose()}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        </div>
      ) : (
        <div className={cn(classes.controlDialog, classes.flex)}>
          <IconButton
            className={cn(classes.closeButton, classes.flex)}
            onClick={() => handleClose()}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      )}

      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentBox}>
          <div className={classes.logoContainer}>
            <img src={sankakuLogo} alt="Sankaku Complex" loading="lazy" />
          </div>
          {factor === AuthFactor.AUTHENTICATOR && renderQRForm}
          {factor === AuthFactor.TOTP && otp.sentCode && renderEnterEmailOtpForm}
          {factor === AuthFactor.TOTP && !otp.sentCode && renderGetOtpForm}
          {factor && (
            <DialogActions className={classes.dialogActions}>
              {factor === AuthFactor.AUTHENTICATOR ? (
                <Button
                  color="primary"
                  onClick={() => {
                    handleClose()
                    history.push({
                      pathname: '/reset-mfa',
                      query: location.query,
                    })
                  }}
                  type="button"
                >
                  {t('dialog__verify-otp_reset')}
                </Button>
              ) : null}
            </DialogActions>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

VerifyCode.whyDidYouRender = true

export default reduxForm({ form: 'verify_code' })(VerifyCode)
